

































































































































































import vueFilePond, { VueFilePondComponent } from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import WidgetMixins from '../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { currencyFormat, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IProduct } from '@/types/types';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { image, min_value, numeric, required } from 'vee-validate/dist/rules';
import { Watch } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import { FilePondFile } from 'filepond';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid amount provided',
});
extend('image', {
  ...image,
  message: 'Input must be an image',
});

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode,
  FilePondPluginImageTransform,
  FilePondPluginFileMetadata,
);
const productsModule = namespace('products');
@Component({
  name: 'Edit',
  components: {
    FilePond,
    VueEditor,
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    Notifications: loadWidget('pages/Notifications'),
    ValidationObserver,
    ValidationProvider,
    ProgressLoader: loadWidget('widgets/CircularProgressLoader'),
  },
  filters: {
    currencyFormat,
  },
})
export default class Edit extends mixins(WidgetMixins) {
  @productsModule.Getter('getAllProducts') products!: IProduct[];
  @productsModule.Getter('getProductDetails') details!: IProduct;

  search: string = '';
  toolbar: Array<Array<string | object>> = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['link', 'code-block', 'blockquote'],
  ];
  rules: any = [(value: any) => !value || value.size < 1000000 || 'Avatar size should be less than 1 MB!'];

  myFile: string[] = [];
  previewImage: string = '';
  coverImage: File | null = null;
  product: Pick<IProduct, 'name' | 'description' | 'logo' | 'url' | 'appSubPath'> = {
    name: '',
    description: '',
    logo: '',
    url: '',
    appSubPath: '',
  };

  get filteredProducts() {
    return this.products.filter((product: IProduct) => {
      return product.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
    });
  }

  getFile(): void {
    let file = this.$refs.pond as VueFilePondComponent;
    if (file?.getFiles().length === 0) {
      if (file && this.product.logo) {
        file.addFiles([`data:image/png;base64,${this.product.logo}`]);
      }
    }
  }

  @Watch('details')
  onProductDetails(payload: IProduct) {
    let file = this.$refs.pond as VueFilePondComponent;
    this.product = { ...payload };
    if (file && this.product.logo) {
      file.addFiles([`data:image/png;base64,${this.product.logo}`]);
    }
  }

  editProduct() {
    let file = this.$refs.pond as VueFilePondComponent;
    let typedFiles = file?.getFiles() as Array<FilePondFile & { getFileEncodeBase64String: () => string }>;
    this.$store.dispatch('products/update', {
      id: this.$route.params.id,
      body: {
        ...this.product,
        logo: typedFiles.length > 0 ? typedFiles[0].getFileEncodeBase64String() : null,
      },
    });
  }
  created(): void {
    if (this.products.length === 0) {
      this.$store.dispatch('products/list');
    }
    this.$store.dispatch('products/details', { id: this.$route.params.id });
  }
}
